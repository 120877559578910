export enum HumecloudApps {
  HUMECORE = "humecore",
  HUMEDIRECTORY = "humedirectory",
  HUMEFAN = "humefan",
  HUMEHEALTH = "humehealth",
  HUMEORDER = "humeorder",
  HUMETIPS = "humetips",
  HUMECHARITY = "humecharity",
  HUMEHOSPITALITY = "humehospitality",
  HUMEIPTV = "humeiptv",
  HUMENU = "humenu",
  SCREENLAB = "screenlab"
}
