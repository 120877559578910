import {Injectable} from '@angular/core';
import Keycloak from 'keycloak-js';
import {BehaviorSubject, combineLatest, from, Observable, ReplaySubject, Subject} from 'rxjs';
import {distinct, switchMap} from 'rxjs/operators';
import {UserRoles} from "../../interfaces/role";
import {SimpleAppUtil} from '../../util/simple-app.util';

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {

  static auth: any = {};
  static onTokenExpired$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private token$ = new ReplaySubject<string>(1);
  private refreshedToken$ = new Subject<string>();

  constructor() { }

  static getRealmName(fullDomain: string = window.location.hostname): string | null {
    const simpleAppName = SimpleAppUtil.getSimpleAppName(fullDomain)

    const domainParts = fullDomain.split('\.')
    const realmName = domainParts.length >= 3 && this.isRealmPart(domainParts[0])
      ? domainParts[0] : null
    return realmName === simpleAppName ? null : realmName
  }

  private static isRealmPart(domainPart: string): boolean {
    return domainPart != "manager" && domainPart != "zeiss" && domainPart != "koesio"
      && SimpleAppUtil.getSimpleAppNameForDomainPart(domainPart) == null
  }

  static init(url: string, defaultRealm: string, clientId : string): Promise<any> {

    let realmName = KeycloakService.getRealmName()

    const keycloakAuth: Keycloak = new Keycloak({
      url: url,
      realm: realmName ? realmName : defaultRealm,
      clientId: clientId,
      // 'ssl-required': 'external',
      // 'public-client': true
    });

    KeycloakService.auth.loggedIn = false;
    KeycloakService.auth.defaultRealm = defaultRealm;

    return new Promise<void>((resolve, reject) => {
      keycloakAuth.onTokenExpired = function () {
        KeycloakService.onTokenExpired$.next(true)
      }
      // .success and .error are not handled by the angular builder but they exists
      // ts-ignore cancel the error
      keycloakAuth.init({ onLoad: 'login-required', checkLoginIframe: false })
        //@ts-ignore
        .then(() => {
          KeycloakService.auth.loggedIn = false;
          KeycloakService.auth.authz = keycloakAuth;
          resolve();
        })
        .catch((err) => {
          console.error('[ERROR]', err);
          reject();
        });
    });
  }

  login(): void {
    KeycloakService.auth.authz.login().then(() => {
      KeycloakService.auth.loggedIn = true;
    });
  }

  logout(): void {
    KeycloakService.auth.authz.logout({ redirectUri: document.baseURI })
      .then(() => {
        KeycloakService.auth.loggedIn = false;
        KeycloakService.auth.authz = null;
      });
  }

  getTokenPromise(refresh: boolean = false): Promise<string> {
    let secondsBeforeExpiration = 90
    if (refresh) {
      secondsBeforeExpiration = -1
    }
    return new Promise<string>((resolve, reject) => {
      if (KeycloakService.auth.authz.token) {
        KeycloakService.auth.authz
          .updateToken(secondsBeforeExpiration) // refresh the token if it will expire in 90 seconds or less
          .then((refreshed: any) => {
            this.token$.next(KeycloakService.auth.authz.token)
            if (refreshed) {
              this.refreshedToken$.next(KeycloakService.auth.authz.token)
            }
            resolve(<string>KeycloakService.auth.authz.token);
          })
          .catch(() => {
            reject('Failed to refresh token');
          });
      } else {
        reject('Not logged in');
      }
    });
  }

  getToken(refresh: boolean = false): Observable<string | null> {
    return from(this.getTokenPromise(refresh));
  }

  getTokenObservable(): Observable<string> {
    return this.token$.pipe(distinct())
  }

  getAutoRefreshToken(): Observable<string> {
    this.getToken()
    return combineLatest([this.getTokenObservable(), KeycloakService.onTokenExpired$]).pipe(
      switchMap(_ => this.getToken() as Observable<string>),
      distinct()
    )
  }

  getKeycloakAuth() {
    return KeycloakService.auth.authz;
  }

  isLoggedIn(): boolean {
    return KeycloakService.auth.authz.authenticated;
  }

  getEmail(): string {
    return KeycloakService.auth.authz?.tokenParsed.email;
  }

  getUsername(): string {
    return KeycloakService.auth.authz?.tokenParsed.preferred_username;
  }

  getPhoneNumber(): string {
    return KeycloakService.auth.authz?.tokenParsed.phoneNumber;
  }

  getCustomerIds(): string[] {
    return KeycloakService.auth.authz?.tokenParsed.cids;
  }

  getApiUserId(): string {
    return KeycloakService.auth.authz?.tokenParsed.apiUserId;
  }

  getRoles(): UserRoles[] {
    let userRolesStrs: string[] = KeycloakService.auth.authz?.tokenParsed.roles;
    if (!userRolesStrs) {
      return [];
    }
    let userRoles: UserRoles[] = []
    for (let userRolesStr of userRolesStrs) {
      let userRolesStrParts = userRolesStr.split(":")
      if (userRolesStrParts.length != 2) {
        // ignore malformed
        console.warn(userRolesStr + " role is malformed")
        continue
      }
      let roleId = userRolesStrParts[1]
      let customerIds = userRolesStrParts[0].split(",")
      userRoles.push({
        roleId,
        customerIds
      } as UserRoles)
    }
    return userRoles
  }

  getFirstName(): string {
    return KeycloakService.auth.authz?.tokenParsed.given_name;
  }

  getLastName(): string {
    return KeycloakService.auth.authz?.tokenParsed.family_name;
  }

  getFullName(): string {
    return KeycloakService.auth.authz?.tokenParsed.name;
  }

  getRefreshedToken(): Observable<string> {
    return this.refreshedToken$
  }

  redirectToAccountPage() {
    KeycloakService.auth.authz.accountManagement().then(() => {
      // do nothing
    });
  }

  getRealm(): string {
    return KeycloakService.auth.authz.realm;
  }

  getDefaultRealm(): string {
    return KeycloakService.auth.defaultRealm;
  }

  usesDefaultRealm(): boolean {
    return this.getDefaultRealm() === this.getRealm();
  }
}
