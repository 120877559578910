import { Injectable } from '@angular/core';
import { HumecloudApps } from '../constants/humecloud-apps';


@Injectable({
  providedIn: 'root'
})
export class SimpleAppUtil {

  simpleApps = []

  constructor() {
    // do nothing
  }

  public getSimpleAppName(fullDomain: string = window.location.hostname): string | null {
    return SimpleAppUtil.getSimpleAppName(fullDomain)
  }

  static getSimpleAppName(fullDomain: string = window.location.hostname): string | null {
    let domainParts = fullDomain.split('\.')
    if (domainParts.length >= 3) {
      let simpleAppName = this.getSimpleAppNameForDomainPart(domainParts[0]);
      if (simpleAppName) return simpleAppName
    }
    if (domainParts.length >= 4) {
      let simpleAppName = this.getSimpleAppNameForDomainPart(domainParts[1]);
      if (simpleAppName) return simpleAppName
    }
    return null
  }

  static getSimpleAppNameForDomainPart(domainPart: string): string | null {
    switch (domainPart) {
      case HumecloudApps.HUMEDIRECTORY: {
        return HumecloudApps.HUMEDIRECTORY
      }
      case HumecloudApps.HUMEFAN: {
        return HumecloudApps.HUMEFAN
      }
      case "humeservices":
      case HumecloudApps.HUMEHEALTH: {
        return HumecloudApps.HUMEHEALTH
      }
      case HumecloudApps.HUMENU: {
        return HumecloudApps.HUMENU
      }
      case HumecloudApps.SCREENLAB: {
        return HumecloudApps.SCREENLAB
      }
      default: {
        return null
      }
    }
  }
}
